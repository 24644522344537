<template>
    <div class="chartBox topBarBox">
        <div class="flexBetween" style="height: 100%;">
            <div class="flexCloumnCenter"
                style="width: 30%;color: #01C4F7;font-size: 20px;font-weight: 700;cursor: pointer;">
                <div class="flexCenter" @click="goPage">
                    <i class="el-icon-s-home" style="font-size: 24px;"></i>
                    <span>主页</span>
                </div>
            </div>
            <div class="flexCenter"
                style="width: 40%;white-space: nowrap;font-size: 28px;font-family: Source Han Sans CN;font-weight: bold;color: #01C4F7;">
              {{ $store.state.config.appname }}
            </div>
            <div class="flexCloumnCenter" style="width: 30%;color: #01C4F7;">
                <span>.</span>
                <span>{{ curTime }}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            curTime: '',
            weeks: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
            timer: null,
            leftTopDatatype: "zs"
        };
    },
    mounted() {
        let _this = this;
        this.timer = setInterval(function () {
            _this.getTime()
        }, 1000);
    },
    methods: {
        goPage() {
            let strMenu = localStorage.getItem('firstMenu')
            
            if (strMenu) {
                
                this.$router.push(strMenu)
                this.$store.dispatch("showSmenu", 1)
                this.$store.dispatch("showSmenu", 1)
                this.$store.dispatch("smenuCount", 1)
            }

        },
        changeDatatype(e) {
            this.$parent.leftTopDatatype = e
            this.leftTopDatatype = e

        },
        add0(value) {
            return value < 10 ? '0' + value : value;
        },
        getTime() {
            let date = new Date(),
                year = date.getFullYear(),
                month = this.add0(date.getMonth() + 1),
                day = this.add0(date.getDate()),
                hours = this.add0(date.getHours()),
                minutes = this.add0(date.getMinutes()),
                seconds = this.add0(date.getSeconds()),
                week = date.getDay();
            this.curTime = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds} ${this.weeks[week]}`
        }
    },
    beforeDestroy: function () {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
    width: 100%;
    height: 100%;

    div {
        box-sizing: border-box;
    }
}

.topBarBox {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/topbar_bg.png');
}

.barckd {
    color: #1890ff;
    font-weight: bolder;
}
</style>

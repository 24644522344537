<template>
  <div class="chartBox flexCloumnBetween">

      <div class="titleFrame flexCenter">{{ title }}</div>
      <div class="contentFrame flexCloumnCenter"
           style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px; padding-left: 20px; padding-right: 20px;">
        <div class="flexEvenly w_100">
          <div v-for="(item,index) in myMenus" class="flexCloumnCenter" style="width: 15%;" @click="goPage(item.path)">
            <div class="icon" :style="{backgroundImage:'url('+item.icon+')'}"></div>
            <div class="flexCenter" style="margin-top: 20px;">{{ item.title }}</div>
          </div>
        </div>

      </div>

  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  components: {},
  data() {
    return {
      xibuId: 0,
      showFull: false,
      step: 65.5,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,
      datashow: false,
      myMenus: [
        {icon: require('../../../../public/img/home/menu01.png'), title: '耗材申购',path:'/sx_purchase'},
        {icon: require('../../../../public/img/home/menu02.png'), title: '耗材入库',path:'/sx_ruku'},
        {icon: require('../../../../public/img/home/menu03.png'), title: '耗材领用',path:'/sx_shenling'},
        // {icon: require('../../../../public/img/home/menu04.png'), title: '耗材派发',},
        // {icon: require('../../../../public/img/home/menu05.png'), title: '耗材维修',},
      ]
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      // _this.autoScroll('#animBox')

    }, 5000)

  },
  methods: {
    showData(a) {
      console.log(a)
      if (a.pid == 0) {
        this.xibuId = a.id;
        this.datashow = true
      }
    },
    goPage(path){
      if(path) this.$router.push(path)
    },
  },

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .icon {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

}
</style>

<template>
  <div class="chartBox flexCloumnBetween">

    <div class="titleFrame flexCenter">{{ title }}</div>
    <div class="contentFrame flexCloumnCenter"
         style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px; padding-left: 20px; padding-right: 20px;">
      <div id="chart_hcUtilization" style="width: 100%; height: 100%;"></div>
    </div>
  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  components: {},
  data() {
    return {
      xibuId: 0,
      showFull: false,
      step: 65.5,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,
      datashow: false,
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    this.draw()
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      // _this.autoScroll('#animBox')

    }, 5000)

  },
  methods: {
    getData() {
      let _this = this
      let t= new Date().getTime()
      return new Promise(resolve => {
        _this.$http.post("/api/consumables_use_statistics").then(res => {
          console.log("请求耗时consumables_use_statistics",new Date().getTime()-t)
          resolve(res.data)
        })
      })
    },
    draw() {
      let _this = this
      this.getData().then(result => {
        let myChart = this.$echarts.init(document.getElementById("chart_hcUtilization"));
        let data = result&&result.length>0&&result.map(e => {
          e.name = e.title
          e.value = e.num
          return e})


        let option = {
          color: [  '#3ba272','#91cc75', '#fac858', '#ee6666', '#ea7ccc',  '#9a60b4'],
          series: [
            {
              type: 'pie',
              radius: ['10%', '70%'],
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8,
                borderColor: '#fff',
                borderWidth: 1
              },

              label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} %}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  name: {
                    fontSize: 14,
                    color: '#fff'
                  },
                  time: {
                    fontSize: 10,
                    color: '#fff'
                  }
                }
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
              },
              labelLayout: function (params) {
                const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                const points = params.labelLinePoints;
                // Update the end point.
                points[2][0] = isLeft
                    ? params.labelRect.x
                    : params.labelRect.x + params.labelRect.width;
                return {
                  labelLinePoints: points
                };
              },
              data:data
            }
          ]
        };
        myChart.setOption(option);
      })

    },
  },

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .icon {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

}
</style>

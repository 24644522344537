<template>
  <div class="chartBox flexCloumnBetween">

    <div class="titleFrame flexCenter">{{ title }}</div>
    <div class="contentFrame contentFrame2 flexCloumnCenter"
         style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding: 30px 20px 5px; position: relative;">
      <dv-scroll-board v-if="false" :config="config" style="width: 100%;height: 100%;overflow-y: hidden"/>
      <div class="d-flex jc-center body-box scrollTableContainer"
           style="height:calc(100% - 30px);margin-top: 10px;overflow: hidden">
        <div class="tableHeaderContainer flexStart">
          <div style="width: 20px!important;"
               v-html="`#`"
          />
          <div class="header-item overText"
               v-for="(headerItem, i) in config.header"
               :key="i"
               v-html="headerItem"
          />
        </div>
        <div ref="#animBox" class="tableBodyContainer"
             @mouseenter="enterbox" @mouseleave="leavebox">
          <div
              class="row-item flexStart"
              v-for="(row, ri) in config.data"
              :key="ri">
            <div style="width: 20px!important;"
                 v-html="`${ri+1}`"
            />
            <div
                class="ceil overText"
                v-for="(ceil, ci) in row"
                :key="ci"
                v-html="ceil"/>

          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  components: {},
  data() {
    return {
      showFull: false,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,


      config: {
        header: [],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#051946", //表头
        oddRowBGC: "#060D2A", //奇数行
        evenRowBGC: "#03103C", //偶数行
        index: true,
        columnWidth: [45],
        align: ["center"],
        waitTime: 3000,
      },
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    this.getList()
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      _this.autoScroll('#animBox')
    }, 1000)
  },
  methods: {
    getList() {
      let _this = this
      this.loading = true
      let params = {
        page: 1,
        pagesize: 100,
      }
      this.$http.post('/api/zc_ly_formlist', params).then(res => {
        let majorlist = [];
        let dataArr = []
        for (let item of res.data) {
          let objArr = []
          objArr.push(item.create_user)
          objArr.push(item.mingcheng || '')
          objArr.push(item.pinpai || '')
          objArr.push(item.xinghao || '')
          objArr.push(item.shulinag || '')
          // item.cdate = item.cdate ? item.cdate.substr(0, 10) : ''
          objArr.push(item.cdate || '')
          objArr.push(item.beizhu || '')
          dataArr.push(objArr || '')
        }
        console.log(7878, dataArr)
        this.config.header = ['领用人', '耗材名称', '耗材品牌', '耗材型号', '领用数量 ', '领用时间', '领用备注']
        this.config.data = dataArr
        this.config = {..._this.config}
        this.loading = false
      })
    },

    leavebox() {
      clearTimeout(this.set1)
      clearTimeout(this.set2)
      clearTimeout(this.set3)
      clearInterval(this.timer)
      this.autoScroll('#animBox', 10)
    },
    enterbox() {
      clearTimeout(this.set1)
      clearTimeout(this.set2)
      clearTimeout(this.set3)
      clearInterval(this.timer)
    },
    autoScroll(dom) {

      let _this = this
      let parentDom = this.$refs[dom]
      if (parentDom) {


        this.timer = setInterval(() => {
          //判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
          if ((parentDom.scrollTop + parentDom.clientHeight) < parentDom.scrollHeight) {
            parentDom.scrollTop++ // 元素自增距离顶部
          } else {
            clearTimeout(this.set1)
            clearTimeout(this.set2)
            clearTimeout(this.set3)
            clearInterval(_this.timer)
            this.set2 = setTimeout(function () {
              parentDom.scrollTop = 0
            }, 1000)
            this.set3 = setTimeout(function () {
              _this.autoScroll('#animBox')
            }, 3000)
          }
        }, _this.stepTime)
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    next(vm => {
    })
    clearTimeout(this.set1)
    clearTimeout(this.set2)
    clearTimeout(this.set3)
    clearInterval(this.itimertl)
  },
  beforeDestroy() {
    clearTimeout(this.set1)
    clearTimeout(this.set2)
    clearTimeout(this.set3)
    clearInterval(this.timer)
  },
  destroyed() {
    clearTimeout(this.set1)
    clearTimeout(this.set2)
    clearTimeout(this.set3)
    clearInterval(this.timer)
    window.onresize = null;
  },

};
</script>

<style lang="less" type="text/less">
.dv-scroll-board {
  height: 100%;
  overflow: hidden;

  .rows {
    height: 100%;
    overflow: hidden;
  }
}

.scrollTableContainer {
  .dv-scroll-board {
    .header-item, .ceil {
      padding: 0;
    }
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .barBox {
    width: 56px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .bar1 {
    width: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .littlebg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: 1;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/frame_bg1_little.png');
  }

  //padding: 0.2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.0625rem;

  .bg-color-black {
    height: calc(100% - 30px);
    overflow: hidden;
    border-radius: 0.125rem;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    height: 100%;
    border-radius: 0.125rem;
    overflow: hidden;
  }
}

.overText {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scrollTableContainer {
  width: 100%;
  height: 100%;

  .tableHeaderContainer {
    width: 100%;
    height: calc(100% / 11);

    .header-item {
      flex: 1;
      text-align: center;
    }

    .header-item:nth-of-type(7),.header-item:nth-of-type(8) {
      flex: 0 0 160px;

    }

  }

  .tableBodyContainer {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .row-item {
      width: 100%;
      height: calc(100% / 10);

      .ceil {
        flex: 1;
        text-align: center;

      }

      .ceil:nth-of-type(7),.ceil:nth-of-type(8)  {
        flex: 0 0 160px;

      }
    }

    .row-item:nth-of-type(odd) {
      background-color: #191e35;
    }


  }
}

</style>
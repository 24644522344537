<template>
  <div class="chartBox flexCloumnBetween">

    <div class="titleFrame flexCenter">{{ title }}</div>
    <div class="contentFrame flexCloumnCenter"
         style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px; padding-left: 20px; padding-right: 20px;">


      <div class="flexEvenly w_100">
        <div v-for="(item,index) in myMenus" class="flexCloumnCenter" style="width: 20%;">
          <div class="icon1" style="position: relative">
              <div class="wh_100" :id="'chartYuj_'+item.id"  style=" position: absolute;width: 100%;height: 100%;"> </div>
<!--              <dv-water-level-pond class="icon1" :config="myMenus[0]" style="width: 100%;height: 100%;" />-->


          </div>
          <div class="flexCenter overOneLine" style="margin-top: 10px;">{{ item.title }}</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";
import 'echarts-liquidfill'
export default {
  components: {},
  data() {
    return {
      xibuId: 0,
      showFull: false,
      step: 65.5,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,
      datashow: false,
      myMenus: [
        {
          data: [],
          shape: "round",
          formatter: "{value}",
          waveNum: 2,
          title: ''
        },


      ]
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    this.draw()
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      // _this.autoScroll('#animBox')

    }, 5000)

  },
  methods: {
    getData() {
      let t= new Date().getTime()
      let _this = this
      return new Promise(resolve => {
        _this.$http.post("/api/warning_information_statistics").then(res => {
          console.log("请求耗时warning_information_statistics",new Date().getTime()-t)
          let arr = []
          if (res.data && res.data.length > 0) {
            _this.myMenus = res.data
            resolve(res.data)
           
          }else {
            resolve( [])
          }
        })

      })
    },
    draw() {
      let _this = this
      this.getData().then(result => {
        if(result&&result.length>0){
          result.map((a,ia)=>{
            let chart1 = this.$echarts.init(document.getElementById('chartYuj_' + a.id));
            let colorList = [
              ['#294D99', '#156ACF', '#1598ED', '#45BDFF'],
              ['#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8'],
              ['#7827e6','#8d39ec', '#aa4ff6','#ea80fc'],
              ['#c848b9', '#f962a7', '#fd836d', '#f7ba79'],
              ['#028174', '#0ab68b', '#92de88', '#ffe3b3'],
            ]
            let option = {
              series: [{
                color:colorList[ia],
                type: 'liquidFill',
                data: [0.1, 0.45, 0.3, 0.2],
                radius: '90%',
                outline: {
                  show: true,
                  borderDistance: 4,
                  itemStyle: {
                    color: 'none',
                    borderColor: '#02D9FD',
                    borderWidth: 2,
                    shadowBlur: 20,
                    shadowColor: 'rgba(0, 0, 0, 0.25)'
                  }
                },
                backgroundStyle: {
                  color: '#E3F7FF21',
                  borderColor: '#156ACF',
                  borderWidth: 1,
                  shadowColor: 'rgba(0, 0, 0, 0.4)',
                  shadowBlur: 20
                },
                label: {
                  position: ['50%', '50%'],
                  formatter: function(params) {
                    return `${a.num}${a.jldw}`;
                  },
                  fontSize: 20,
                  color: '#02D9FD'
                }
              }],

            };
            chart1.setOption(option);
          })

        }
      })

    },
    showData(a) {
      console.log(a)
      if (a.pid == 0) {
        this.xibuId = a.id;
        this.datashow = true
      }
    },
  },

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .icon1 {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
  }

}
</style>

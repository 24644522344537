<template>
    <div class="chartBox flexCloumnBetween">

          <div class="titleFrame flexCenter">{{title}}</div>
          <div v-if="myMenus&&myMenus.length>0" class="contentFrame flexCloumnCenter" style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px;">
            <div class="flexAround w_100">
              <div class="flexStart" v-for="(item,index) in myMenus[0]" @click="goPage(item.path)" style="cursor: pointer;">
                <div class="flexCenter daiban_bgleft">{{ item.num }}</div>
                <div class="flexCenter overOneLine daiban_bgright">{{ item.title }}</div>
              </div>
            </div>
            <div class="flexAround w_100">
              <div class="flexStart" v-for="(item,index) in myMenus[1]" @click="goPage(item.path)" style="cursor: pointer;">
                <div class="flexCenter daiban_bgleft">{{ item.num }}</div>
                <div class="flexCenter overOneLine daiban_bgright">{{ item.title }}</div>
              </div>
            </div>
          </div>
    </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";
export default {
    components: {
    },
    data() {
        return {
            xibuId: 0,
            showFull: false,
            step: 65.5,
            timer: null,
            stepTime: 50,
            set1: null,
            set2: null,
            set3: null,
            datashow: false,
          myMenus:[]
        };
    },
    props: {
      title:'',
        pmid: {
            type: Number,
            default: 1
        },
    },
    mixins: [echartMixins],
    mounted() {
        let _this = this
      this.getData()
        clearInterval(this.timer)
        this.set1 = setTimeout(() => {
            // _this.autoScroll('#animBox')

        }, 5000)

    },
    methods: {
      getData() {
        let t= new Date().getTime()
        let _this = this
        return new Promise(resolve => {
          let arr = []
          _this.$http.post("/api/agency_statistics").then(res => {
            console.log("请求耗时agency_statistics",new Date().getTime()-t)
            if(res.data&&res.data.length>4){
              res.data.map(a=>{
                if(a.title.indexOf('申购')>-1){a.path = '/sx_purchase'}
                if(a.title.indexOf('入库')>-1){a.path = '/sx_ruku'}
                if(a.title.indexOf('派发')>-1){}
                if(a.title.indexOf('领用')>-1){a.path = '/sx_shenling'}
                if(a.title.indexOf('维修')>-1){}
              })

              this.myMenus = []
              arr.push([res.data[0],res.data[1]])
              // arr.push([res.data[2],res.data[3],res.data[4]])
              arr.push( [res.data[3]])
            }
            this.myMenus = arr
            console.log(82,this.myMenus)
            resolve(res.data)
          })

        })
      },
      goPage(path){
        if(path) this.$router.push(path)
      },
        showData(a) {
            console.log(a)
            if (a.pid == 0) {
                this.xibuId = a.id;
                this.datashow = true
            }
        },
        leavebox() {
            clearTimeout(this.set1)
            clearTimeout(this.set2)
            clearTimeout(this.set3)
            clearInterval(this.timer)
            // this.autoScroll('#animBox', 10)
        },
        enterbox() {
            clearTimeout(this.set1)
            clearTimeout(this.set2)
            clearTimeout(this.set3)
            clearInterval(this.timer)
        },
        autoScroll(dom) {
            let _this = this
            let parentDom = this.$refs[dom]
            // let parentDom = document.querySelector(dom) //获取滚动元素

            this.timer = setInterval(() => {
                //判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                // console.log(92,dom,parentDom)
                if ((parentDom.scrollTop + parentDom.clientHeight) < parentDom.scrollHeight) {
                    parentDom.scrollTop++ // 元素自增距离顶部
                } else {
                    clearTimeout(this.set1)
                    clearTimeout(this.set2)
                    clearTimeout(this.set3)
                    clearInterval(_this.timer)
                    this.set2 = setTimeout(function () {
                        parentDom.scrollTop = 0
                    }, 5000)
                    this.set3 = setTimeout(function () {
                        // _this.autoScroll('#animBox')
                    }, 10000)
                }
            }, _this.stepTime)
        },
    },
    beforeRouteLeave(to, from, next) {
        next(vm => {
        })
        clearTimeout(this.set1)
        clearTimeout(this.set2)
        clearTimeout(this.set3)
        clearInterval(this.itimertl)
    },
    beforeDestroy() {
        clearTimeout(this.set1)
        clearTimeout(this.set2)
        clearTimeout(this.set3)
        clearInterval(this.timer)
    },
    destroyed() {
        clearTimeout(this.set1)
        clearTimeout(this.set2)
        clearTimeout(this.set3)
        clearInterval(this.timer)
        window.onresize = null;
    },
};
</script>

<style scope lang="less" type="text/less">
.chartBox {
    width: 100%;
    height: 100%;

    //隐藏滚动条
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .anim {
        transition: all 5s;
        margin-top: -65.5px;
    }


  .daiban_bgleft{
    color: #02D9FD;
    font-weight: 700;
    z-index: 1;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/daiban_bgleft.png');
  }
  .daiban_bgright{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/daiban_bgright.png');
  }
  @media screen and (max-width:  1080px) {
    .daiban_bgleft{
      width: 48px;
      height: 40px;
    }
    .daiban_bgright{
      height: 32px;
      line-height: 32px;
      margin-left: -24px;
      padding-left: 24px;
      padding-right: 10px;
    }
  }
  @media screen and (min-width:  1080px) {
    .daiban_bgleft{
      width: 68px;
      height: 58px;
    }
    .daiban_bgright{
      height: 50px;
      line-height: 50px;
      margin-left: -34px;
      padding-left: 40px;
      padding-right: 28px;
    }
  }
}

.prograssBarBox {
    .boxItem {
        height: 65.5px;

        //padding: 15px 10px;
        .itemContainer {
            width: 100%;
        }

        .rank {
            width: 40px;
            color: #1370fb;
        }
    }
}
</style>

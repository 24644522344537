<template>
  <div class="chartBox flexCloumnBetween">

      <div class="titleFrame flexCenter">{{ title }}</div>
      <div class="contentFrame contentFrame1 flexCloumnCenter"
           style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px; position: relative;padding-left: 20px; padding-right: 20px;">
        <div class="littlebg"/>
        <div class="flexBetween w_100" style="margin-top: 20px;margin-bottom: 20px;">
          <div></div>
          <div class="" style="
font-size: 20px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
background: linear-gradient(0deg, #3A80F9 0%, #5CC2E0 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;">我的资产数量分布</div>
          <div class="">单位：件</div>
        </div>
        <div id="chart_mySum" style="width: 100%; height: 100%;"></div>

        <div v-if="false" class="flexEvenly w_100 flex_1" style="z-index: 2;margin-bottom: 20px;">

          <div v-for="(item,index) in myMenus" class="flexCloumnCenter h_100" style="width: 15%;">
            <div class="barBox w_100 flex_1 flexCenterBottom" :style="{backgroundImage:'url('+item.bar0+')'}">
              <div class="bar1 flexCenterBottom" :style="{height:item.rate,backgroundImage:'url('+item.bar1+')'}"></div>
            </div>
            <div class="flexCenter overOneLine" style="margin-top: 20px;font-size: 18px;
font-weight: 500;color: #6DCDE6;">{{ item.title }}</div>
            <div class="flexCenter" style="margin-top: 10px;">{{ item.num }}</div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  components: {},
  data() {
    return {
      xibuId: 0,
      showFull: false,
      step: 65.5,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,
      datashow: false,
      myMenus: [
        {bar0: require('../../../../public/img/home/bar00.png'),bar1: require('../../../../public/img/home/bar01.png'), title: '总资产',num:1000,rate:'100%',},
        {bar0: require('../../../../public/img/home/bar10.png'),bar1: require('../../../../public/img/home/bar11.png'), title: '已申购',num:1000,rate:'50%',},
        // {bar0: require('../../../../public/img/home/bar20.png'),bar1: require('../../../../public/img/home/bar21.png'), title: '已派发',num:1000,rate:'60%',},
        {bar0: require('../../../../public/img/home/bar30.png'),bar1: require('../../../../public/img/home/bar31.png'), title: '已领用',num:1000,rate:'90%',},
        {bar0: require('../../../../public/img/home/bar40.png'),bar1: require('../../../../public/img/home/bar41.png'), title: '库存',num:1000,rate:'20%',},
        // {bar0: require('../../../../public/img/home/bar50.png'),bar1: require('../../../../public/img/home/bar51.png'), title: '维修中',num:1000,rate:'10%',},

      ]
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    this.draw();
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      // _this.autoScroll('#animBox')

    }, 5000)

  },
  methods: {
    getData() {
      let t= new Date().getTime()
      let _this = this
      return new Promise(resolve => {
        let arr = []
        _this.$http.post("/api/asset_summary_statistics").then(res => {
          console.log("请求耗时asset_summary_statistics",new Date().getTime()-t)
          if(res.data&&res.data.length>0){
            res.data.map(a=>{
              this.myMenus.map(b=>{
                if(a.title == b.title){
                  b.num = a.num
                  arr.push(b)
                }
              })
            })
            resolve(arr)
          }else {
            resolve([])
          }
        })

      })
    },
    draw() {
      let _this = this
      this.getData().then(result => {
        this.chart = this.$echarts.init(document.getElementById("chart_mySum"));
        let category = result.map(e => {return `${e.title},${e.num}`})
        let arry0 = [],
         arry1 = [];
        let rateData = [];
        let cityMax = 0;

        if (result && result.length > 0) {
          cityMax = result[0].num ? result[0].num : 0;
          result.map(r => {
            cityMax = cityMax < r.num ? r.num : cityMax
            arry1.push(r.num)
            rateData.push(0.5)
          })

          arry0 = result.map(r => {return cityMax})

        }
        let option1 = {
          // animation: false,
          grid: {
            top:'3%',bottom: 80
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.8)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            },
            formatter: function(params) {
              let tooltiptext = `<div class="flexStart"><span style="display:block;width: 10px;height: 10px;background-color:${params[0].color};border-radius: 50%;margin-right: 10px;"></span><span>${params[0].name}</span></div>
<div><span>计划总人数:${ params[0].value}人</span></div>`
              return tooltiptext
            },
          },
          legend: {
            data: [ "计划招生总人数"],
            textStyle: {
              color: "#B4B4B4"
            },
            right: 0
          },
          xAxis: {
            type: 'category',
            data: category,
            axisLine: {//轴线
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {//轴线刻度
              show: true,
              alignWithLabel: true,  //坐标轴刻度与标签对齐
            },
            axisLabel: {//轴线文字
              show: true,
              interval: 0,
              // rotate: 45,
              color:'#fff',
              formatter: function (value) {
                return value.split('').join('\n')
              }
            },
          },
          yAxis: {

            type: 'value',

            splitLine: { //网格线
              lineStyle: {
                type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
              },
              show: true //隐藏或显示
            },
            axisLine: {//轴线
              lineStyle: {
                color: "#03050c"
              }
            },

            axisLabel: {//轴线文字
              formatter: "{value} ",
              textStyle: {
                color: "#B4B4B4",//Y轴内容文字颜色
              },
            }
          },
          series: [
            {
              name: "计划招生",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: function (params) {
                    let colorList = ['#5AADF3', '#61D8AB',
                      '#5B6E95', '#a7dffa', '#fddb4c',
                      '#fd974d', '#e45a55', '#6d62e4',
                      '#4a6fe1', '#6c99e6', '#23c0d9', '#d3eb5a',
                      '#fde78d', ];
                    return colorList[params.dataIndex]
                  }
                }
              },
              data: arry0
            },

          ]
        };

        let option = {
          grid: {
            top:'3%',bottom: 80
          },
          color:["#1D43F3",'#42A8D9','#5930D9',
            '#A637E1','#CE7E31','#F9303E'],
          "series": [
            {
              "barWidth": 20,
              "data": arry1,
              "itemStyle": {
                "barBorderRadius": 5,
                color: function (params) {
                  let colorList = [
                    {colorStops: [{color: "#1D43F3", "offset": 0}, {color: "#4366F3", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},
                    {colorStops: [{color: "#42A8D9", "offset": 0}, {color: "#6DCDE6", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},
                    {colorStops: [{color: "#5930D9", "offset": 0}, {color: "#8651F4", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},
                    {colorStops: [{color: "#A637E1", "offset": 0}, {color: "#E23AF5", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},
                    {colorStops: [{color: "#CE7E31", "offset": 0}, {color: "#F5A43A", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},
                    {colorStops: [{color: "#F9303E", "offset": 0}, {color: "#FC5E68", "offset": 1}], global: false, "type": "linear", "x": 0, "x2": 0, "y": 0, "y2": 1},

                  ];
                  return colorList[params.dataIndex]
                },

                "color1": {
                  "colorStops": [{"color": "#14c8d4", "offset": 0}, {"color": "#43eec6", "offset": 1}],
                  "global": false,
                  "type": "linear",
                  "x": 0,
                  "x2": 0,
                  "y": 0,
                  "y2": 1
                }
              },
              "name": "已招生",
              "type": "bar"
            },
            // {
            //   "barGap": "-100%",
            //   "barWidth": 20,
            //   "data": arry0,
            //   "itemStyle": {
            //     "color": {
            //       "colorStops": [
            //           {"color": "rgba(20,200,212,0.5)", "offset": 0},
            //         {"color": "rgba(20,200,212,0.2)", "offset": 0.2},
            //         {"color": "rgba(20,200,212,0)", "offset": 1}],
            //       "global": false,
            //       "type": "linear",
            //       "x": 0,
            //       "x2": 0,
            //       "y": 0,
            //       "y2": 1
            //     }
            //   },
            //   "name": "背景",
            //   "type": "bar",
            //   "z": -12
            // },
            {
              "data": arry0,
              "itemStyle": {"color": "#0f375f"},
              "name": "dotted",
              "symbol": "rect",
              "symbolMargin": 1,
              "symbolRepeat": true,
              "symbolSize": [20, 4],
              "type": "pictorialBar",
              "z": -10
            }
            ],
          xAxis: {
            type: 'category',
            data: category,
            axisLine: {//轴线
              show: false,},
            axisTick: {//轴线刻度
              show: false,
              alignWithLabel: true,  //坐标轴刻度与标签对齐
            },
            axisLabel: {//轴线文字
              show: true,
              interval: 0,
              color:'#fff',
              formatter: function (value) {
                let arr = value.split(',')
                return '{' + 'Sunny' + '| ' + arr[0] + '}\n{value|' + arr[1] + '}';
              },

              rich: {
                Sunny: {
                  height: 40,lineHeight:40,
                  marginTop: 20, fontSize: 18,
                  fontWeight: 500, color: '#6DCDE6',
                },
                value: {
                  height: 20,lineHeight:20,
                  marginTop: 20,fontSize: 16,
                },
              },




            }
          },
          yAxis: [
            {
              type: 'value',
              splitLine: { //网格线
                show: false},
              axisLine: {//轴线
                show: false,},
              axisLabel: {//轴线文字
                show: false,}
            },
          ],
        }
        this.chart.setOption(option);
      })

    },
  },

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .barBox {
    width: 56px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bar1 {
    width: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .littlebg{
    position: absolute;bottom: 0;width: 100%;height: 50%;z-index: 1;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/frame_bg1_little.png');
  }
}
</style>

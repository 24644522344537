<template>
  <div class="homeContainer">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div class="topBar">
      <com_topBar></com_topBar>
    </div>

    <div class="chartContainer">
      <div class="flexBetween" style="width: 100%;height: 100%;">
        <div class="flexCloumnBetween" style="width: 70%;height: 100%;">
          <div class="flexBetween" style="width: 100%;height: 56%;">
            <div class="flexCloumnBetween" style="width: 38%;height: 100%;">
              <div class="" style="width: 100%;height: 45%;">
                <com_myTodo title="我的待办"></com_myTodo>
              </div>
              <div class="" style="width: 100%;height: calc( 55% - 20px );">
                <com_myMenu title="常用功能"></com_myMenu>
              </div>
            </div>
            <div class="" style="width: calc( 62% - 20px );height: 100%;padding-top: 10px;">
              <com_mySum title="我的资产汇总"></com_mySum>
            </div>
          </div>
          <div class="" style="width: 100%;height: calc( 44% - 20px );">
            <com_myLY title="领用记录"></com_myLY>
          </div>
        </div>
        <div class="flexCloumnBetween" style="width: calc( 30% - 20px );height: 100%;">
          <div class="" style="width: 100%;height: calc( 22% - 0px );">
            <com_myYujing title="预警信息"></com_myYujing>
          </div>
          <div class="" style="width: 100%;height: calc( 51% - 20px );">
            <com_remain title="剩余库存"></com_remain>

          </div>
          <div class="" style="width: 100%;height: calc( 27% - 20px );">
            <com_hcUtilization title="耗材使用占比"></com_hcUtilization>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import com_topBar from "./com_topBar";
import com_myTodo from "./com_myTodo";
import com_myMenu from "./com_myMenu";
import com_mySum from "./com_mySum";
import com_myLY from "./com_myLY";

import com_myYujing from "./com_myYujing";
import com_remain from "./com_remain";
import com_hcUtilization from "./com_hcUtilization";

export default {
  components: {
    com_topBar,
    com_myTodo, com_myMenu, com_mySum, com_myLY,
    com_myYujing, com_remain, com_hcUtilization,
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
  },
  methods: {},
}
</script>
<style>
.chartBox .bg-color-black {
  height: 100%;
}
</style>
<style scope lang="less" type="text/less">
.w_100 {
  width: 100%;
}

.h_100 {
  height: 100%;
}

.wh_100 {
  width: 100%;
  height: 100%;
}

@import "../../../assets/home.less";

.homeContainer {
  div {
    box-sizing: border-box;
  }

  .pdng10 {
    padding: 10px;
  }

  .pdng_rem_dot2 {
    padding: 0.2rem;
  }

  .border {
    border: 0.5px solid #1a5cd7;
    border-radius: 16px;
  }

  .topBar {
    width: 100%;
    height: 6.8%;
    box-sizing: border-box;
  }

  .chartContainer {
    height: 93.2%;
    padding: 7px 30px 30px 30px;

    .titleFrame {
      width: 40%;
      max-width: 200px;
      height: 30px;
      z-index: 1;
      color: #02D9FD;
      font-weight: 700;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../../../public/img/home/frame_titlebg.png');
    }

    .contentFrame {
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../../../public/img/home/frame_bg.png');
    }

    .contentFrame1 {
      background-image: url('../../../../public/img/home/frame_bg1.png');
    }

    .contentFrame2 {
      background-image: url('../../../../public/img/home/frame_bg2.png');
    }
  }
}

</style>

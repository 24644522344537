<template>
  <div class="chartBox flexCloumnBetween">

    <div class="titleFrame flexCenter">{{ title }}</div>
    <div class="contentFrame contentFrame1 flexCloumnCenter"
         style="width: 100%;height: calc( 100% - 15px);margin-top: -15px;padding-top: 15px;">
      <div id="chart_remain" style="width: 100%; height: 100%;"></div>
    </div>

  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  components: {},
  data() {
    return {
      xibuId: 0,
      showFull: false,
      step: 65.5,
      timer: null,
      stepTime: 50,
      set1: null,
      set2: null,
      set3: null,
      datashow: false,
    };
  },
  props: {
    title: '',
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    this.draw()
    clearInterval(this.timer)
    this.set1 = setTimeout(() => {
      // _this.autoScroll('#animBox')

    }, 5000)

  },
  methods: {
    getData() {
      let _this = this
      let t= new Date().getTime()
      return new Promise(resolve => {
        _this.$http.post("/api/remaining_kucun_statistics").then(res => {
          // console.log(res.data)
          console.log("请求耗时remaining_kucun_statistics",new Date().getTime()-t)
          resolve(res.data)

        })

      })
    },
    draw() {
      let _this = this
      this.getData().then(result => {
        // console.log(64,result)
        this.chart = this.$echarts.init(document.getElementById("chart_remain"));
        let arrx = result.map((e,eidx) => {
          if(eidx==0){
            return `${e.month}月\n${e.year}`
          } else if(e.month==1){
            return `${e.month}月\n${e.year}`
          }else {
            return `${e.month}月`
          }
        })
        let arryL = result.map(e => {
          if(parseFloat(e.num)==0){
            return 0
          }else {
            return (parseFloat(e.num)/10000).toFixed(2)
          }
          })
        let arryR = result.map(e => {return e.item_num})
        let option = {
          grid: {
            top:'10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          "series": [
            {
              name: '数量',
              type: 'line',
              smooth: true, yAxisIndex: 0,
              lineStyle : {
                color : '#02D9FD',
                width : 2,
                type : 'solid',
              },
              //折线连接点样式
              itemStyle : {
                color : '#02D9FD'
              },
              data: arryR,
              areaStyle: {
                normal: {// 渐变填充色（线条下半部分）
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#02D9FDd1" },
                    { offset: 1, color: "#02D9FD00" }
                  ])
                }
              }
            },
            {
              name: '金额',
              type: 'line',
              smooth: true, yAxisIndex: 1,
              lineStyle : {
                color : '#D09227',
                width : 2,
                type : 'solid',
              },
              //折线连接点样式
              itemStyle : {
                color : '#D09227'
              },
              data: arryL,
              areaStyle: {
                normal: {// 渐变填充色（线条下半部分）
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#D09227d1" },
                    { offset: 1, color: "#D0922700" }
                  ])
                }
              }
            },
          ],
          tooltip: {
            trigger: "axis",
            // axisPointer: {
            //   type: "shadow",
            //   label: {
            //     show: true,
            //     backgroundColor: "#7B7DDC"
            //   }
            // },
          },
          xAxis: {
            boundaryGap : false,
            type: 'category',
            data: arrx,
            axisLine: {//轴线
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {//轴线刻度
              show: true,
              alignWithLabel: true,  //坐标轴刻度与标签对齐
            },
            axisLabel: {//轴线文字
              show: true,
              interval: 0,
              color:'#02D9FD',
            },
          },
          yAxis: [
            {
              type: 'value',
              name: '数量(件)',
              // interval: 5,
              splitLine: { //网格线
                lineStyle: {
                  type: 'dashed', //设置网格线类型 dotted：虚线 solid:实线
                  color:'#02D9FD51'
                },
                show: true //隐藏或显示
              },
              axisLine: {//轴线
                lineStyle: {
                  color: "#02D9FD"
                }
              },

              axisLabel: {//轴线文字
                formatter: "{value} ",
                textStyle: {
                  color: "#02D9FD",//Y轴内容文字颜色
                },
              }
            },
            {
              type: 'value',
              name: '金额(万元)',
              // interval: 5,
              splitLine: { //网格线
                lineStyle: {
                  type: 'dotted', //设置网格线类型 dashed、dotted：虚线 solid:实线
                  color: '#F9AF1A51',
                },
                show: true //隐藏或显示
              },
              axisLine: {//轴线
                lineStyle: {
                  // color: "#03050c"
                  color: "#F9AF1A"

                }
              },

              axisLabel: {//轴线文字
                formatter: "{value} ",
                textStyle: {
                  color: "#F9AF1A",//Y轴内容文字颜色
                },
              }
            }
          ],
        }
        this.chart.setOption(option);
      })

    },
    showData(a) {
      // console.log(a)
      if (a.pid == 0) {
        this.xibuId = a.id;
        this.datashow = true
      }
    },
  },

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .anim {
    transition: all 5s;
    margin-top: -65.5px;
  }

  .barBox {
    width: 56px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bar1 {
    width: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .littlebg{
    position: absolute;bottom: 0;width: 100%;height: 50%;z-index: 1;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../public/img/home/frame_bg1_little.png');
  }
}
</style>
